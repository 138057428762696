/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1800px) {
	.goods__item-price {
		font-size: 55px; } }
@media only screen and (max-width : 1700px) {
	.header__menu a {
		margin-right: 15px;
		font-size: 17px; }
	.goods__image {
		width: 55%; } }
@media only screen and (max-width : 1500px) {
	.header__menu a {
		font-size: 15px; }
	.logo {
		width: 200px; }
	.header__menu-subLink:after {
		top: 25px; }
	.header__menu a:before {
		top: -25px; }
	.header__left-block.active span {
		font-size: 11px; }
	.logo.active {
		width: 200px; }
	.goods__item-name {
		font-size: 40px; }
	.goods__item-price {
		font-size: 48px; } }
@media only screen and (max-width : 1200px) {
	.header__row {
		flex-direction: column; }
	.header__menu-link , .footer__top {
		display: none; }
	.video__title {
		font-size: 50px;
		margin-bottom: 40px; }
	.header__menu-open a:before {
		display: none; }
	.header__left-block {
		justify-content: center;
		align-items: center; }
	.goods__title {
		font-size: 30px; }
	.goods__image {
		width: 45%; }
	.goods__item-name {
		font-size: 28px; }
	.goods__item-price {
		font-size: 30px; }
	.footer__social {
		margin-left: 10px; }
	.footer__info, .footer__item p {
		font-size: 13px; }
	.works__sort li {
		font-size: 14px; }
	.works__sort {
		justify-content: center; }
	.workItem__information .workCategory__desc {
		margin-bottom: 15px; }
	.workItem__more {
		margin-top: 0;
		height: 40px;
		font-size: 20px; }
	.workItem__row {
		margin-top: 20px;
		display: flex; }
	.prodItem__information p {
		font-size: 14px; }
	.prodItem__lastRow {
		margin-top: 20px; }
	.prod__list li {
		font-size: 15px; }
	.contacts__list li , .contacts__list li span {
		font-size: 21px; }
	.contacts__address li , .contacts__address li span {
		font-size: 21px; }
	.payments__row .sendForm {
		max-width: 350px; }
	.payments__image {
		max-width: 580px; } }
/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	.goods {
		background: #fff; }
	.header__row , .footer__row , .footer__item {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column; }
	.goods__item-more {
		width: 275px; }
	.footer__item {
		text-align: center; }
	.footer__logo {
		padding: 0;
		margin: 0 0 15px 0;
		border: none; }
	.footer__social {
		margin: 15px 0; }
	.articles__item {
		flex-basis: 48%; }
	.work__item {
		flex-basis: 47%; }
	.workCategory__item {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center; }
	.workCategory__image ,.workItem__image {
		margin: 0 0 15px 0; }
	.workCategory__more , .workItem__more {
		margin: 0 auto; }
	.prodItem__row1 {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px; }
	.prod__list {
		margin-top: 15px;
		padding-left: 15px; }
	.prodItem__row2 {
		flex-direction: column;
		justify-content: center;
		align-items: center; }
	.prodInfo__image {
		margin-left: 0;
		order: -1;
		margin-bottom: 15px; }
	.prodDesc__image {
		margin-right: 0;
		margin-bottom: 15px; }
	.contacts__row {
		flex-direction: column; }
	.contacts__address {
		padding-top: 30px; }
	label {
		font-size: 13px;
		line-height: 24px; }
	.payments__row {
		flex-direction: column; }
	.payments__image {
		order: -1;
		margin: 0 auto; }
	.payments__row .sendForm {
		margin: 0 auto; } }
/* Small Devices, Tablets */
@media only screen and (max-width : 769px) {
	.header__contacts {
		flex-direction: column; }
	.video__title {
		font-size: 30px; }
	.video__info {
		width: 100%;
		padding-left: 0;
		justify-content: center;
		align-items: center;
		text-align: center; }
	.header__left-block.active span {
		display: none; }
	.goods__item {
		flex-direction: column; }
	.goods__image {
		width: 100%;
		margin-bottom: 20px; }
	.goods__item-price {
		margin-bottom: 20px; }
	.goods__info {
		margin-left: 0; }
	.goods__item:nth-child(odd) .goods__info {
		order: 1;
		margin-right: 0; }
	.goods__item:nth-child(odd) .goods__item-name , .goods__item-price , .goods__item-name {
		text-align: center; }
	.goods__item:nth-child(odd) .goods__item-more , .goods__item-more {
		margin: 0 auto; }
	.production__item {
		flex-basis: 31%; }
	.work__desc {
		font-size: 14px; }
	.work__name {
		font-size: 18px;
		margin-bottom: 15px; }
	.workItem__row {
		justify-content: center;
		flex-wrap: wrap; }
	.prodItem__block {
		flex-basis: 33%; }
	.payments p {
		font-size: 14px; }
	.payments__blockBottom {
		flex-direction: column; } }
/* Extra Small Devices, Phones */
@media only screen and (max-width : 576px) {
	.header__contacts div {
		font-size: 11px;
		margin-right: 0; }
	#menu a {
		font-size: 12px; }
	.mm-navbar {
		height: auto;
		a {
			width: 100%;
			padding: 5px 0!important; } }
	.video__top {
		font-size: 20px; }
	.video__info {
		height: calc(100% - 49px); }
	.goods__title {
		margin-bottom: 30px; }
	.production__item {
		flex-basis: 48%; }
	.articles__item {
		flex-basis: 100%; }
	.articles__bottom {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center; }
	.articles__name {
		text-align: center;
		font-size: 14px;
		margin-bottom: 5px; }
	.work__item {
		flex-basis: 87%; }
	.workCategory__title {
		font-size: 16px; }
	.workCategory__desc, .workCategory__name , .contacts__address li, .contacts__address li span, .contacts__list li, .contacts__list li span {
		font-size: 14px; }
	.projectSwitch {
		justify-content: center; }
	.workItem__more , .contacts__email {
		width: 100%; }
	.prodItem__block {
		flex-basis: 50%; }
	.contacts__border {
		padding-left: 10px; }
	.inputs__calc {
		flex-wrap: wrap; } }
@media only screen and (max-width : 480px) {
	.modal, .sendForm {
		width: 98%;
		height: auto; }
	.sendForm__title {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 20px; }
	.video__btn {
		width: 90%;
		height: 40px;
		font-size: 18px; }
	.video__title {
		font-size: 24px;
		margin-bottom: 20px; }
	.goods__title {
		font-size: 20px; }
	.work__item , .prodItem__block , .production__item {
		flex-basis: 100%; }
	.work__btn {
		width: 100%;
		font-size: 16px; }
	.works__sort li {
		width: 100%;
		text-align: center; }
	.payments__blockTitle {
		font-size: 18px; }
	.payments__blockName {
		font-size: 16px; }
	label {
		flex-wrap: wrap; }
	.inputs__list {
		padding: 0;
		margin-top: 5px; }
	.sendForm button {
		font-size: 16px; } }
/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	/**/ }


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 769px) {
	.header__menuOpen {
		display: none; } }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	.container {
		max-width: 98%; }
	.header__menu-open {
		display: none; } }
@media only screen and (min-width : 1400px) {
	.works__sort li {
		padding: 10px 20px; } }
